import Vue from "vue";
import Vuex from "vuex";
import materials from "./materials";
import courses from "./courses";
import user from "./user";
import admin from "./admin";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: user,
    materials: materials,
    courses: courses,
    admin: admin,
  },
});
