<template>
  <div class="bg space">
    <p class="d-flex justify-center mt-3">Copyright © 2022 | LeoLearning</p>
  </div>
</template>

<script>
export default {
  name: "FooterApp",
};
</script>

<style>
.space {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.bg {
  background-color: #d6d6d6;
}
</style>
